import React, { useEffect, useRef, useState } from "react";
import InputBox from "../../Atom/Input/InputField";
import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
import MyDatePicker from "../../Atom/Date/Date";
import ImageGallery from "../../Atom/Image/Image";
import Tags from "../../Atom/Tags/tagsV2";
import OrganisationIcon from "../../../assets/images/svg/Organisation.svg";
import AssigneeIcon from "../../../assets/images/svg/Assignee.svg";
import CommentsIcon from "../../../assets/images/svg/Comments.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateTask, updateTaskTags } from "../../../store/Task/index"; // Import the updateTask action
import { toast } from 'react-toastify';
import useCustomComposable from "../../utils/composables";
import { addTagHelper } from '../../Atom/Tags/helper';
import { useSidebar } from "../../context/SidebarContext/SidebarContext";
import TaskDetails from "../../organisms/taskDetails";
import DragIcon from "../../../assets/images/svg/Drag_icon.svg";
const TaskRow = ({
    row,
    handleStatusChange,
    handleDueDateChange,
    handleOrganisationChange,
    handleAssigneeChange,
    handleCommentsChange,
    handleTagsChange,
    handleTaskChange,
    index,
    sectionTags
}) => {
    const { openSidebar } = useSidebar();
    const dispatch = useDispatch();
    const { makeUniqueId } = useCustomComposable();

    const allTags = useSelector((state) => state.tags.tags);
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(row.value || "");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage sidebar
    const inputRef = useRef(null)

    const [taskTags, setTaskTags] = useState([]);
    const [taskSectionTags, setSectionTags] = useState([]);

    useEffect(() => {
        const tmpTaskTags = row.tags.filter((x) => !sectionTags.includes(x))?.map((x) => allTags.find(y => y.id === x))
        const tmpSectionTags = sectionTags?.map((x) => allTags.find(y => y.id === x))
        setTaskTags(tmpTaskTags.filter((x) => x));
        setSectionTags(tmpSectionTags.filter((x) => x));

    }, [row.tags, sectionTags])

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleOptionSelect = (options) => {
        setSelectedOptions(options);
    };
    // Enable editing on double-click
    const handleDoubleClick = () => {
        setIsEditing(true);
        setInputValue(row.value); // Reset the input value when entering edit mode
        setTimeout(() => {
            inputRef.current?.focus()
        });
    };

    // Handle input change
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // Save value and exit edit mode on Enter or blur
    const handleBlurOrEnter = (e) => {
        if (e.type === "blur" || (e.type === "keydown" && e.key === "Enter")) {
            if (inputValue.length >= 3) {
                dispatch(updateTask({ id: row.id, field: 'value', value: inputValue }));
                setIsEditing(false);
                setInputValue(row.value);
            } else {
                alert("Input must be at least 3 characters long.");
            }
        }
    };

    // Function to handle row click: log row ID and toggle sidebar
    const handleRowClick = () => {
        openSidebar("Task Details", <TaskDetails rowId={row.value} taskTags={taskTags} sectionTags={taskSectionTags}/>);
    };
    function handleAddTag(tagName) {
        if (!tagName) return;

        const allTaskTags = [...taskSectionTags, ...taskTags];
        const tagValue = allTaskTags.find(x => x.value === tagName)

        if (tagValue) {
            toast.error("Please enter a unique tag.");
            return;
        }

        const existingTag = allTags.find(tag => tag.value === tagName);

        // Add the tag
        if (existingTag) {
            dispatch(updateTaskTags({ taskId: row.id, tags: [...row.tags, existingTag.id] }));
            toast.success(`Tag "${existingTag.value}" added successfully!`);
        } else {
            const uniqueId = makeUniqueId(); // Implement this function to generate a unique ID
            addTagHelper({ id: uniqueId, value: tagName }, dispatch); // Ensure this helper function works correctly
            dispatch(updateTaskTags({ taskId: row.id, tags: [...row.tags, uniqueId] }));
            toast.success(`New tag "${tagName}" created and added!`);
        }
    }
    const handleRemoveTag = (tag) => {
        const updatedTags = row.tags.filter((x) => x !== tag.id);
        dispatch(updateTask({ id: row.id, field: 'tags', value: updatedTags }));
        toast.success(`Tag "${tag.value}" removed successfully!`);
    };

    return (
        <tr>
            <td onClick={handleRowClick}>
                <div className="taskNameCell">
                 <img src={DragIcon}/>   
                {isEditing ? (
                    <InputBox
                        ref={inputRef}
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleBlurOrEnter}
                        onKeyDown={handleBlurOrEnter} // Use only onKeyDown for handling Enter
                        autoFocus
                    />
                ) : (
                    <span onDoubleClick={handleDoubleClick}>
                        {row.value || "Double-click to edit"}
                    </span>
                )}
                </div>
            </td>
            <td>
                <CircularProgressBar
                    percentage={row.status}
                    onChange={(e) => handleStatusChange(e, row.id)}
                />
            </td>
            <td>
                <MyDatePicker
                    indexrow={index}
                    selectedDate={row.dueDate}
                    onChange={(date) => handleDueDateChange(date, row.id)}
                />
            </td>
            <td>
                {row.organisation ? (
                    <ImageGallery images={[row.organisation]} />
                ) : (
                    <img src={OrganisationIcon} alt="Organisation" />
                )}
            </td>
            <td>
                {row.assignee ? (
                    <ImageGallery images={[...row.assignee]} shape="circle" />
                ) : (
                    <img src={AssigneeIcon} alt="Assignee" />
                )}
            </td>
            <td>
                <img src={CommentsIcon} alt="Comments" />
            </td>
            <td>
                <Tags
                    tags={taskTags || []}
                    tagOptions={allTags || []}
                    handleAddTag={handleAddTag}
                    handleRemoveTag={handleRemoveTag}
                    onRemoveTag={handleRemoveTag}
                />
            </td>
            <td>
                <Tags
                    tags={taskSectionTags || []}
                    disabled={true}
                    handleRemoveTag={handleRemoveTag}
                    taskId={row.id}
                    onRemoveTag={handleRemoveTag}
                />
            </td>
        </tr>
    );
};

export default TaskRow;
