import React from 'react';
import './MainLayout.css';

const MainLayout = ({ sidebar, children, leftContentSize = "165px", rightContentSize = "100%" }) => {
  return (
    <div className="layout">
      <div  className="left-content style-scroll" style={{width: leftContentSize}}>
          {sidebar}
      </div>
      <div  className="right-content" style={{width: rightContentSize}}>
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
