import React, { forwardRef } from 'react';
import './Style.css';

const InputBox = forwardRef(({ value, onChange, placeholder, disabled = false, onKeyPress,onKeyDown,onBlur ,title}, ref) => {
  return (
    <input
      className='input'
      type="text"
      value={value}
      title={title}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={onBlur}
      ref={ref} // Make sure ref is passed
      onKeyDown={onKeyDown}// Make sure onKeyDown is passed
      onKeyPress={onKeyPress}// Make sure onKeyDown is passed
    />
  );
});

export default InputBox;
