import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

// Add this hook export
export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};

export const SidebarProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [component, setComponent] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');

    const openSidebar = (title, component,bgColor = '#FFFFFF') => {
        setTitle(title);
        setComponent(component);
        setBackgroundColor(bgColor);
        setIsOpen(true);
    };

    const closeSidebar = () => {
        setIsOpen(false);
        setTitle('');
        setComponent(null);
    };

    return (
        <SidebarContext.Provider value={{
            isOpen,
            title,
            component,
            backgroundColor, 
            openSidebar,
            closeSidebar
        }}>
            {children}
        </SidebarContext.Provider>
    );
};