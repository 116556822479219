import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import "./Style.css";
import DragIcon from "../../../assets/images/svg/Drag_icon.svg";
import InputBox from "../../Atom/Input/InputField";
import RangeBar from "../../Atom/RangBar/rangBar";
import Tags from "../../Atom/Tags/tagsV2";
import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
import MyDatePicker from "../../Atom/Date/Date";
import OrganisationIcon from "../../../assets/images/svg/Organisation.svg";
import Comments from "../../../assets/images/svg/Comments.svg";
import AssigneeIcon from "../../../assets/images/svg/Assignee.svg";
import Dropdown from "../../../assets/images/svg/DropDown_arrow.svg";
import TaskRow from "./TaskRow";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { addTagHelper, removeTagHelper } from "../../Atom/Tags/helper";
import { addTagToSection, updateSection, updateSectionTags } from "../../../store/Section/index";
import useCustomComposable from "../../utils/composables";
import AddRow from "./addRow";
import data from '../../../data.json'; // Import the JSON file
const ItemType = "SECTION";

const DraggableSection = ({
    id,
    index,
    moveSection,
    item,
    inputValue,
    handleInputChange,
    taskValue,
    handleTaskChange,
    addRow,
    handleTaskRowChange,
    tags: sectionTags,
    onCreateTask,
}) => {
    const [tableHeaderData, setTableHeaderData] = useState([]);

    // Fetch tableHeader from localStorage or use default data
    useEffect(() => {
        const savedData = getTableHeaderFromLocalStorage();

        if (savedData) {
            setTableHeaderData(savedData); // Set state with saved data
        } else {
            console.log("No table header data found in localStorage, using default.");
            setTableHeaderData(data.tableHeader); // Default data
        }
    }, []); // Empty dependency array ensures this runs only once on mount

    // Fetch table header data from localStorage
    const getTableHeaderFromLocalStorage = () => {
        const storedData = localStorage.getItem("tableHeader");
        console.log("Stored data from localStorage:", storedData); // Check what's being fetched
        if (storedData) {
            return JSON.parse(storedData); // Parse and return the stored data
        } else {
            return null; // No stored data, return null
        }
    };

    // Apply saved widths to table on render
    useEffect(() => {
        if (tableHeaderData.length > 0) {
            tableHeaderData.forEach((header, index) => {
                const cells = document.querySelectorAll(`tr > *:nth-child(${index + 1})`);
                cells.forEach((cell) => {
                    cell.style.width = `${header.columnWidth}px`;
                    cell.style.minWidth = `${header.columnWidth}px`;
                });
            });
        }
    }, [tableHeaderData]);

    // Handle column resizing
    const handleResize = (e, index) => {
        e.preventDefault();
        e.stopPropagation();

        const th = e.target.closest("th");
        const table = th.closest("table");
        const resizeHandle = e.target;

        // Add visual feedback
        resizeHandle.classList.add("resizing");
        document.body.style.cursor = "col-resize";

        const startX = e.clientX;
        const startWidth = th.offsetWidth; // Initial width of the column
        const colIndex = Array.from(th.parentNode.children).indexOf(th);

        // Minimum width for the current column
        const minWidth = colIndex === 0 ? 300 : 147;

        // Get all cells in this column (including the header cell)
        const cells = table.querySelectorAll(`tr > *:nth-child(${colIndex + 1})`);

        const onMouseMove = (event) => {
            event.preventDefault();
            const deltaX = event.clientX - startX;

            // Calculate the new width, ensuring it doesn't go below the minimum width
            const newWidth = Math.max(startWidth + deltaX, minWidth);

            // Update the header cell width
            th.style.width = `${newWidth}px`;

            // Update all other cells in the column to match the new width
            cells.forEach((cell) => {
                cell.style.width = `${newWidth}px`;
                cell.style.minWidth = `${newWidth}px`;
            });
        };

        const onMouseUp = () => {
            // Remove visual feedback
            resizeHandle.classList.remove("resizing");
            document.body.style.cursor = "";

            // Create a copy of tableHeaderData and update columnWidth
            const updatedTableHeaderData = [...tableHeaderData];
            updatedTableHeaderData[index] = {
                ...updatedTableHeaderData[index],
                columnWidth: th.offsetWidth, // Save only the width
            };

            // Update the state with the new column width
            setTableHeaderData(updatedTableHeaderData);

            // Store the updated tableHeader in localStorage
            localStorage.setItem("tableHeader", JSON.stringify(updatedTableHeaderData));

            // Remove event listeners
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
            document.removeEventListener("selectstart", preventDefault);
        };

        const preventDefault = (e) => e.preventDefault();

        // Prevent text selection while resizing
        document.addEventListener("selectstart", preventDefault);
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const [tags, setTags] = useState(item.tags);
    const [isSectionVisible, setIsSectionVisible] = useState(true);

    const { makeUniqueId } = useCustomComposable();

    const allTags = useSelector((state) => state.tags.tags);
    const selectedTags = useSelector((state) => state.tagFilter.selectedTags); // Get selected tags from the store
    const inputRef = useRef(null);
    const tasks = useSelector((state) => state.tasks.tasks);
    const [sectionTagsData, setSectionTagsData] = useState([]);
    const [rows, setRows] = useState([]);

    const [isEditing, setIsEditing] = useState(item.isEditing || false);
    const [sectionName, setSectionName] = useState(item.value);
    const dispatch = useDispatch();

    const sections = useSelector(state => state.sections.sections);

    const handleSectionNameChange = (e) => {
        setSectionName(e.target.value);
    };

    useEffect(() => {
        setSectionName(item.value);
    }, [item.value]);

    const handleSectionNameBlur = () => {
        if (sectionName.trim()) {
            dispatch(updateSection({
                id: item.id,
                field: 'value',
                value: sectionName.trim()
            }));
            setIsEditing(false);
        }
    };

    // For section name editing
    const handleSectionNameKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const trimmedName = sectionName.trim();
            if (trimmedName.length >= 3) {
                handleSectionNameBlur();
                inputRef.current?.blur();
            } else {
                toast.error('Section name must be at least 3 characters long');
                // Keep focus on input
                inputRef.current?.focus();
            }
        }
    };

    // Get tasks for a specific section
    const getTasksForSection = () => {
        const tmp = tasks.filter((task) => task.sectionId === id);
        setRows(tmp);
    };

    useEffect(() => {
        getTasksForSection();
    }, [tasks]);

    const handleTagRemove = (index) => {
        setTags((prevTags) => prevTags.filter((_, i) => i !== index));
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            addRow(taskValue); // Call addRow function to add a new row
        }
    };

    const [, ref] = useDrag({
        type: ItemType,
        item: { id, index },
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveSection(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const toggleVisibility = () => {
        setIsSectionVisible(!isSectionVisible);
    };

    // HANDLE TAGS
    useEffect(() => {
        const tmpSectionTags = sectionTags?.map((x) => allTags.find(y => y.id === x))?.filter((x) => x);
        setSectionTagsData(tmpSectionTags || []);
    }, [sectionTags, allTags]);

    function handleAddTag(tagName) {
        if (!tagName) return;

        const allTaskTags = [...sectionTagsData];
        const tagValue = allTaskTags.find(x => x.value === tagName);

        if (tagValue) {
            toast.error("Please enter a unique tag.");
            return;
        }

        const existingTag = allTags.find(tag => tag.value === tagName);

        // Add the tag
        if (existingTag) {
            dispatch(addTagToSection({ sectionId: item.id, tag: existingTag.id }));
            toast.success(`Tag "${existingTag.value}" added successfully!`);
        } else {
            const uniqueId = makeUniqueId(); // Implement this function to generate a unique ID
            addTagHelper({ id: uniqueId, value: tagName }, dispatch); // Ensure this helper function works correctly
            dispatch(addTagToSection({ sectionId: item.id, tag: uniqueId }));
            toast.success(`New tag "${tagName}" created and added!`);
        }
    }

    const handleRemoveTag = (tag) => {
        const removeTagId = sectionTags.find((x) => x === tag.id);
        dispatch(updateSectionTags(item.id, removeTagId, 'remove'));
        toast.success(`Tag "${tag.id}" removed successfully!`);
    };

    // Filter rows based on selected tags
    const filteredRows = selectedTags.length > 0
        ? rows.filter(row => row.tags.some(tag => selectedTags.includes(tag))) // Show only tasks that have at least one of the selected tags
        : rows; // Show all tasks if no tags are selected
    return (
        <div className="main-wrapper">
            <div ref={(node) => ref(drop(node))} className="section-header">
                <div className="section-left">
                    <img className="section-drag-icon" src={DragIcon} alt="DragIcon" />
                    <img
                        className={`section-dropdown-icon ${!isSectionVisible ? "rotate" : ""}`}
                        src={Dropdown}
                        alt="Dropdown"
                        onClick={toggleVisibility}
                        style={{ cursor: "pointer" }}
                    />
                    <InputBox
                        ref={inputRef}
                        title={sectionName}
                        value={sectionName}
                        onChange={handleSectionNameChange}
                        onBlur={handleSectionNameBlur}
                        onKeyDown={handleSectionNameKeyPress}
                        autoFocus={isEditing}
                        id={`section-input-${item.id}`}
                    />
                    <RangeBar initialValue={0} />
                </div>
            </div>
            <div className={`section-tags ${!isSectionVisible ? "hidden" : ""}`}>
                All <Tags tags={sectionTagsData} tagOptions={allTags} handleAddTag={handleAddTag} handleRemoveTag={handleRemoveTag} />
            </div>
            <div className={`style-scroll section-table ${!isSectionVisible ? "hidden" : ""}`}>
                <table className="tasks-table">
                    <thead>
                        <tr className="tasks-table-header">
                            {tableHeaderData.map((header, index) => (
                                <th
                                    key={index}
                                    style={{
                                        width: `${header.columnWidth}px`,
                                        minWidth: `${header.columnWidth}px`,
                                    }}
                                >
                                    {header.label}
                                    <span
                                        className="resize-handle"
                                        onMouseDown={(e) => handleResize(e, index)}
                                    ></span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <AddRow
                            taskValue={taskValue}
                            handleTaskChange={handleTaskChange}
                            sectionTagsData={sectionTagsData}
                            sectionId={id}  // Pass section ID to AddRow
                            onCreateTask={onCreateTask}
                        />
                        {filteredRows.map((row, rowIndex) => (
                            <TaskRow
                                key={rowIndex}
                                row={row}
                                sectionTags={sectionTags}
                                handleTaskChange={(e) => handleTaskRowChange(e, row.id)}
                                handleStatusChange={(status) => handleTaskRowChange(status, row.id)}
                                handleDueDateChange={(date) => handleTaskRowChange(date, row.id)}
                                handleOrganisationChange={(organisation) => handleTaskRowChange(organisation, row.id)}
                                handleAssigneeChange={(assignee) => handleTaskRowChange(assignee, row.id)}
                                handleCommentsChange={(comments) => handleTaskRowChange(comments, row.id)}
                                handleTagsChange={(tagIndex) => handleTaskRowChange(tagIndex, row.id)}
                                index={row.id}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DraggableSection;