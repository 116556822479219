import { createSlice } from "@reduxjs/toolkit";

const loadFromLocalStorage = (key) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : [];
};

const saveToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

const tagfilterSlice = createSlice({
    name: 'tagFilter',
    initialState: {
        selectedTags: loadFromLocalStorage('selectedTags'), 
        allTags: loadFromLocalStorage('tags'),
        tasks: loadFromLocalStorage('Tasks'), 
    },
    reducers: {
        addFilterTag: (state, action) => {
            const tag = action.payload;
            if (!state.selectedTags.includes(tag)) {
                state.selectedTags.push(tag); 
                saveToLocalStorage('selectedTags', state.selectedTags); // Save to local storage
            }
        },
        removeFilterTag: (state, action) => {
            const tag = action.payload;
            // Filter out the tag to be removed
            state.selectedTags = state.selectedTags.filter(t => t !== tag);
            saveToLocalStorage('selectedTags', state.selectedTags); // Save to local storage
        },
        resetFilter: (state) => {
            state.selectedTags = []; // Reset selected tags to an empty array
            saveToLocalStorage('selectedTags', state.selectedTags); // Save to local storage
        },
    }
});

// Export actions
export const { addFilterTag, removeFilterTag, resetFilter } = tagfilterSlice.actions;

// Export the reducer
export default tagfilterSlice.reducer;