// Badge.js
import React from 'react';
import './badge.css';

const Badge = ({ text, color, backgroundColor, onClick, className, selected }) => {
    return (
        <span 
            className={`badge ${className || ''}`}
            style={{ 
                color: selected ? '#FFFFFF' : color || '#333', // Change text color to white if selected
                backgroundColor: selected ? '#3787EB' : backgroundColor || '#E8E8E8', // Change background color if selected
            }}
            onClick={onClick} // Call the onClick function passed as a prop
        >
            {text}
        </span>
    );
};

export default Badge;