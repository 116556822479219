// TaskDetails.js
import React from "react";
import Arrow from '../../assets/images/svg/Left_arrow.svg'; // Adjust path if needed

const TaskDetails = ({ onClose, rowId,taskTags,sectionTags }) => {
    return (
        <>
            {/* <div style={{ padding: "10px", zIndex: "1111", display: "flex", gap: "10px", borderBottom: "1px solid rgba(237, 238, 244, 1)" }}>
                <div>
                    <img src={Arrow} alt="Arrow" onClick={onClose} />
                </div>
                <div>
                    Task Detail
                </div>
            </div> */}
            <div style={{ padding: "10px" }}>
                <span>Row ID: {rowId}</span> {/* Display the row ID */}
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    {/* <thead>
                        <tr>
                            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Column 1</th>
                            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Column 2</th>
                        </tr>
                    </thead> */}
                    <tbody>
                        <tr>
                            <td style={{  padding: "8px" }}>Assignee</td>
                            <td style={{  padding: "8px" }}>Data 2</td>
                        </tr>
                        <tr>
                            <td style={{ padding: "8px" }}>Due Date</td>
                            <td style={{ padding: "8px" }}>Data 2</td>
                        </tr>
                        <tr>
                            <td style={{  padding: "8px" }}>Section</td>
                            <td style={{  padding: "8px" }}>Data 2</td>
                        </tr>
                        <tr>
                            <td style={{  padding: "8px" }}>Task Tags: </td>
                            <td style={{  padding: "8px" }}>{taskTags?.map((tag) => tag.value).join(", ")}</td>
                        </tr>
                        <tr>
                            <td style={{  padding: "8px" }}>Section Tags: </td>
                            <td style={{  padding: "8px" }}>{sectionTags?.map((tag) => tag.value).join(", ")}</td>
                        </tr>

                        {/* Add more rows as needed */}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default TaskDetails;
