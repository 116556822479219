import React, { useState } from 'react';
import DragIcon from "../../../assets/images/svg/Drag_icon.svg";
import InputBox from "../../Atom/Input/InputField";
import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
import MyDatePicker from "../../Atom/Date/Date";
import OrganisationIcon from "../../../assets/images/svg/Organisation.svg";
import Comments from "../../../assets/images/svg/Comments.svg";
import AssigneeIcon from "../../../assets/images/svg/Assignee.svg";
import Tags from "../../Atom/Tags/tagsV2";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import useCustomComposable from "../../utils/composables";
import { addTagHelper } from "../../Atom/Tags/helper";

const AddRow = ({
    taskValue,
    handleTaskChange,
    sectionTagsData,
    sectionId,
    onCreateTask
}) => {
    const dispatch = useDispatch();
    const { makeUniqueId } = useCustomComposable();
    const allTags = useSelector((state) => state.tags.tags);
    const [taskTags, setTaskTags] = useState([]);

    const handleAddTag = (tagName) => {
        if (!tagName) return;

        // Check if tag exists in section tags
        const tagExistsInSection = sectionTagsData.some(
            tag => tag.value.toLowerCase() === tagName.toLowerCase()
        );

        if (tagExistsInSection) {
            toast.error("This tag already exists in the section. Please enter a unique tag.");
            return;
        }

        const existingTag = allTags.find(tag => tag.value === tagName);

        if (existingTag) {
            setTaskTags(prev => [...prev, existingTag]);
            toast.success(`Tag "${existingTag.value}" added to task!`);
        } else {
            const uniqueId = makeUniqueId();
            const newTag = { id: uniqueId, value: tagName };
            addTagHelper(newTag, dispatch);
            setTaskTags(prev => [...prev, newTag]);
            toast.success(`New tag "${tagName}" created and added to task!`);
        }
    };

    const handleRemoveTag = (tag) => {
        setTaskTags(prev => prev.filter(t => t.id !== tag.id));
        toast.success(`Tag "${tag.value}" removed from task!`);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && taskValue.trim()) {
            e.preventDefault();
            onCreateTask({
                taskName: taskValue,
                sectionId: sectionId,
                tags: taskTags.map(tag => tag.id)
            });
            // Clear task tags after creation
            setTaskTags([]);
        }
    };

    return (
        <tr className="tasks-table-header">
            <td>
            <div className="taskNameCell">
            <img src={DragIcon}/>   
                <InputBox
                    value={taskValue}
                    placeholder={"write a task name"}
                    onChange={handleTaskChange}
                    onKeyPress={handleKeyPress}
                />
                </div>
            </td>
            <td>
                <CircularProgressBar percentage={0} />
            </td>
            <td>
                <MyDatePicker />
            </td>
            <td>
                <img src={OrganisationIcon} alt="Organisation" />
            </td>
            <td>
                <img src={AssigneeIcon} alt="AssigneeIcon" />
            </td>
            <td>
                <img src={Comments} alt="Comments" />
            </td>
            <td>
                <Tags
                    tags={taskTags}
                    tagOptions={allTags}
                    handleAddTag={handleAddTag}
                    handleRemoveTag={handleRemoveTag}
                />
            </td>
            <td>
                <Tags
                    tags={sectionTagsData}
                    disabled={true}
                />
            </td>
        </tr>
    );
};

export default AddRow;